import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
import moment from "moment";
import { Button, Form, Field, NavBar, Icon, Toast } from 'vant';
import 'vant/lib/index.css';
import '@/assets/css/tailwindcss.css'
import "@/assets/fonts/iconfont.css";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Toast);

Vue.filter("timeFormat", function (value, str = "YYYY-MM-DD HH:mm:ss") {
  return moment(value).format(str);
});
Vue.filter("formatTimestamp", function (value, str = "YYYY-MM-DD HH:mm:ss") {
  return moment.unix(value).format(str);
});
Vue.prototype.$moment = moment;

async function loadConfig() {
  const configUrl = process.env.VUE_APP_CONFIG_URL;
  const response = await fetch(configUrl);
  const config = await response.json();
  Vue.prototype.$config = config;
}

loadConfig().then(() => {
  new Vue({
    render: (h) => h(App),
    beforeCreate() {
      Vue.prototype.$bus = this;
    },
    router,
  }).$mount("#app");
}).catch(error => {
  console.error('Failed to load configuration:', error);
});

