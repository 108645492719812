<template>
  <div class="top-container">
    <div class="top-bar-wrapper">
      <div class="top-bar">
        <!-- 左侧菜单 -->
        <div class="left-menu">
          <!-- 创建菜单图标 -->
          <a href="#" @click="changeMenuVisible">
            <van-icon :name="showMenu?'cross':'list-switch'" color="#fff" />
          </a>
        </div>

        <!-- 手机标题 -->
        <h1 class="mobile-title">
          {{$route.meta.title}}
        </h1>
        <!-- 用户信息 -->
        <div class="mobile-share">
          <a href="#" @click="shareWeb">
            <van-icon name="share-o" color="#fff" />
          </a>
        </div>

        <div class="new_left_tips">
          欢迎来到峰行科技
        </div>
<!--        <div class="new_right_links">-->
<!--          <p><img src="../assets/images/new_home_phone.png" alt=""/>开发咨询热线：</p>-->
<!--          <a href="tel:+8617688158011">176-8815-8011</a>-->
<!--        </div>-->
      </div>
      <!-- 创建菜单 -->
      <ul class="mobile-nav" v-show="showMenu" @click="changeMenuVisible">
        <li v-for="item in menu">
          <router-link replace :to="item.linkTo">{{ item.title }}</router-link>
        </li>
      </ul>
    </div>
    <div class="new_header_nav">

      <div class="new_left_logo">
        <a href="https://www.fxcxy.com"><img src="../assets/images/new_home_logo.png" alt=""/></a>
      </div>

      <ul class="new_right_nav">
        <li v-for="item in menu">
          <router-link replace :exact="item.linkTo=='/'" active-class="new_active" :to="item.linkTo">{{ item.title }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import menu from "../data/menu.json"
import NativeShare from 'nativeshare'

export default {
  name: "WebHeader",
  data() {
    return {
      menu,
      showMenu: false
    }
  },
  mounted() {
    this.nativeShare = new NativeShare()
    // 设置分享文案
    this.nativeShare.setShareData({
      icon: 'https://pic3.zhimg.com/v2-080267af84aa0e97c66d5f12e311c3d6_xl.jpg',
      link: 'https://www.fxcxy.com',
      title: '峰行科技',
      desc: '峰行科技(fxcxy)是一家专业做手机应用软件开发与服务的公司，业务涵盖APP开发、手机APP软件定制开发、app开发外包、APP运营、微信商城开发、微信小程序开发、APP定制开发等',
    })
  },
  methods: {
    changeMenuVisible() {
      this.showMenu = !this.showMenu;
    },
    shareWeb() {
      if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
        this.$toast.info('图文分享请打开QQ浏览器')
      } else {
        try {
          console.log('此浏览器不支持跳转', this.nativeShare)
          this.nativeShare.call()
        } catch (err) {
          console.log('此浏览器不支持跳转', err)
          this.$toast.info('此浏览器不支持跳转', {position: 'bottom', duration: 1000})
        }
      }
    },
  },
}
</script>

<style scoped lang="less">

.top-container{
  position: fixed;
  left:0;
  right:0;
  z-index: 9999;
}
.top-bar-wrapper {
  background: #0A71FF;
}

.top-bar {
  height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.menu-icon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// 设置菜单
.mobile-nav {
  position: fixed;
  top: 48px;
  left: 0;
  bottom: 0;
  right: 0;
  // 设置背景颜色
  background-color: #000000cc;
  padding-top: 60px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline-start: 0px;
  margin-bottom: 0;

  li {
    width: 80%;
    margin: 0 auto;
    border-bottom: 0.5px solid #f7f7f780;

    a {
      display: block;
      line-height: 44px;
      font-size: 14px;
      color: white;
    }

    &:last-child a {
      display: inline-block;
      margin-right: 6px;
    }

    span {
      color: #fff;
      font-size: 14px;
    }
  }

}


// 设置左侧的导航
.left-menu:extend(.menu-icon) {
}

.new_active{
  border-bottom-color: #0a71ff !important;
  color: #0a71ff !important;
  font-weight: bold !important;
}

//设置logo
.logo {
  a {
    display: block;

    img {
      width: 150px;
      height: 30px;
    }
  }
}

.mobile-title {
  font-size: 18px;
  color: white;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

// 设置右侧的分享
.mobile-share:extend(.menu-icon) {

}

.new_left_tips, .new_right_links, .new_header_nav {
  display: none;
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .top-container{
      position: unset;
    }
    .left-menu, .mobile-title, .mobile-share {
      display: none;
    }

    .top-bar {
      max-width: 1200px;
      margin: 0 auto;
      .new_left_tips, .new_right_links {
        display: flex;
        color: #fff;
        font-size: 14px;
        align-items: center;
      }

      .new_right_links {
        p {
          margin-bottom: 0;
          vertical-align: center;

          img {
            margin-right: 5px;
            object-fit: contain;
            width: 20px;
          }
        }

        a {
          color: #fff;
          display: block;
          line-height: 40px;
          text-decoration: none;
        }
      }
    }

    .new_header_nav {
      max-width: 1200px;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
      .new_left_logo {
        a {
          img {
            width: 150px;
          }
        }
      }

      .new_right_nav {
        display: flex;

        li{
          height: 80px;
          margin: 0 20px;
          a{
            border-bottom: 2px solid transparent;
            color: #333333;
            font-size: 16px;
            line-height: 78px;
            font-weight: normal;
            text-decoration: none;
            display: block;
            &:active,&:hover{
              border-bottom-color: #0a71ff;
              color: #0a71ff;
              font-weight: normal;
            }
          }
        }
      }
    }
  }

}


</style>
