<template>
  <div>
    <van-swipe
      ref="mySwipe"
      :autoplay="4000"
      indicator-color="white"
    >
      <van-swipe-item v-for="(p, index) in bannerData" :key="index">
        <img
          @click="openChat()"
          class="d-block img-fluid w-100"
          :src="p.picUrl"
          alt="image slot"
        >
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import api from "../../net/api";
import axios from "../../net/axios";
// Import Vant components
import { Swipe, SwipeItem } from 'vant';

export default {
  name: "Banner",
  // Register Vant components
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      bannerData: [
        {
          "id": 0,
          "imgPath": require("../../assets/images/new_index_banner1.jpg")
        },
        {
          "id": 1,
          "imgPath": require("../../assets/images/new_index_banner3.jpg")
        },
        {
          "id": 2,
          "imgPath": require("../../assets/images/new_index_banner2.jpg")
        }
      ],
    }
  },
  methods: {
    openChat() {
      window.openChat()
    },
    getData() {
      axios.post(api.advertiseList)
          .then(res => {
            this.bannerData = res.data;
          })
          .catch(e => {
            console.log(e)
          })
    },
  },
  mounted() {
    this.getData();
    // Remove this line as van-swipe doesn't have a start method
    // this.$refs.mySwipe.start()
  }
}
</script>

<style scoped lang="less">
// Add any necessary styles for van-swipe
.van-swipe {
  // Adjust this to match your desired height
}

.van-swipe-item img {
  width: 100%;
  object-fit: cover;
}
</style>
