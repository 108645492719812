<template>
  <div>
    <div class="service-warp service-bg padding60">
      <div class="w1000">
        <div class="Title-group">
          <h2 class="title-sub">APP定制开发</h2>
          <span style="color: white!important;">APP Development</span>
          <p class="sub-tit">“峰行定制开发，认真做好每一个项目！”</p>
        </div>
        <div class="service-item">
          <div class="sitem">
            <a href="#!">
              <div class="big-icon bg">
                <i class="fa"><img src="../../assets/images/apptu1.png" width="96" alt=""
                                   height="96"/></i>
              </div>
            </a>
            <h4>设计<span>Design</span></h4>
            <p><span>深入调研需求</span><span>友好产品体验</span><span>精美UI视觉设计</span></p>
          </div>
          <div class="sitem">
            <a href="#!">
              <div class="big-icon bg"><i class="fa"><img src="../../assets/images/apptu2.png"
                                                          alt=""
                                                          width="96" height="96"/></i></div>
            </a>
            <h4>安卓开发<span>Android</span></h4>
            <p><span>安卓应用开发</span><span>推广较容易</span><span>系统开放性高</span><span>用户量多</span></p>
          </div>
          <div class="sitem">
            <a href="#!">
              <div class="big-icon bg"><i class="fa"><img src="../../assets/images/apptu3.png"
                                                          alt=""
                                                          width="96" height="96"/></i></div>
            </a>
            <h4>苹果开发<span>iOS</span></h4>
            <p><span>iOS应用开发</span><span>发布平台统一</span><span>适配兼容性较好</span></p>
          </div>
          <div class="sitem">
            <a href="#!">
              <div class="big-icon bg"><i class="fa"><img src="../../assets/images/apptu4.png"
                                                          alt=""
                                                          width="96" height="96"/></i></div>
            </a>
            <h4>运营<span>Marketing</span></h4>
            <p><span>搜索引擎SEO优化</span><span>知名媒体广告投放</span><span>营销活动策划</span><span>服务器代运营托管</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="service-warp">
      <div class="w1000 padding60 sabt sitembg1">
        <div class="service-item-abt tl">
          <h4>APP开发流程</h4>
          <img src="../../assets/images/sitem_bg1.png"/>
          <p><br/>
            1、需求沟通，功能细节沟通，价格协商。<br/>
            2、初步方案确定，APP的主要功能设计以及大概界面构思和设计。<br/>
            3、签订合同，为了确保双方的权益，明确APP开发功能事项及款项。<br/>
            4、产品设计，制作详细的原型图以、UI图以及PRD文档，并经双方书面确认。<br/>
            5、制作开发，包括架构设计、数据库设计、前端代码编写、接口编写、后端代码编写等。<br/>
            5、测试，包括开发阶段的单元测试、集成测试、系统测试、UI测试、兼容性测试、压力测试等。<br/>
            6、验收发布，上线开发好的APP软件。</p>
          <div class="see-btn"><a rel="nofollow" href="/case" class="contact-btn1">欣赏案例
            <van-icon name="arrow" color="#fff" />
          </a></div>
        </div>
      </div>
    </div>
    <div class="service-warp bg-gray">
      <div class="w1000 padding60 sabt sitembg2 clearfix">
        <div class="service-item-abt  fright tr">
          <h4>APP应用系统</h4>
          <img src="../../assets/images/sitem_bg2.png"/>
          <h5>主流的APP系统：</h5>
          <p>
            1、苹果ios系统版本，开发语言一般是Objective-C或Swift；<br/>
            2、安卓Android系统版本，开发语言是Java或Kotlin；<br/>
            3、微软Windows phone系统版本，开发语言是C#；<br/>
            4、塞班symbian系统版本，开发语言是C++。</p>
          <div class="see-btn"><a rel="nofollow" href="/case" class="contact-btn1">欣赏案例
            <van-icon name="arrow" color="#fff" />
          </a>
          </div>
        </div>
      </div>
    </div>
    <div class="service-warp">
      <div class="w1000 padding60 sabt sitembg3 clearfix">
        <div class="service-item-abt tl">
          <h4>APP开发工具</h4>
          <img src="../../assets/images/sitem_bg3.png"/>
          <p><br/>
            1、Android Studio<br/>
            Android Studio 是用于开发 Android 应用的官方集成开发环境 (IDE)，以 IntelliJ IDEA 为基础构建而成。除了 IntelliJ 强大的代码编辑器和开发者工具，Android
            Studio 还提供更多可提高 Android 应用构建效率的功能。<br/>
            2、Xcode<br/>
            Xcode是苹果公司向开发人员提供的集成开发环境，用于开发macOS、iOS、iPadOS、WatchOS和tvOS的应用程序。<br/>
            3、VSCode<br/>
            VSCode（全称：Visual Studio Code）是一款由微软开发且跨平台的免费源代码编辑器。该软件支持语法高亮、代码自动补全（又称 IntelliSense）、代码重构、查看定义功能，并且内置了命令行工具和
            Git 版本控制系统。<br/>
          </p>
          <div class="see-btn"><a rel="nofollow" href="/case" class="contact-btn1">欣赏案例
            <van-icon name="arrow" color="#fff" />
          </a></div>
        </div>
      </div>
    </div>
    <div class="service-warp bg-gray">
      <div class="w1000 padding60 sabt sitembg4 clearfix">
        <div class="service-item-abt fright tr">
          <h4>APP开发报价</h4>
          <img src="../../assets/images/sitem_bg4.png"/>
          <p><br/>
            1、根据功能多少，开发难易度来决定价格。<br/>
            2、根据界面UI多少来决定价格。<br/>
            3、APP制作价格和APP系统有关，手机APP使用的系统有IOS、Android等2大主流系统，每种系统类型的APP开发价格都是不一样的。
          </p>
          <div class="see-btn"><a rel="nofollow" href="/case"
                                  class="contact-btn1">相关价格介绍</a></div>
        </div>
      </div>
    </div>
    <ServiceProcessPrice/>
  </div>
</template>

<script>
import ServiceProcessPrice from "./ServiceProcessPrice";

export default {
  name: "AppContent",
  components: {ServiceProcessPrice}
}
</script>

<style lang="less">
.service-bg {
  background-image: url("../../assets/images/2.jpg");
  background-position: center center;
  background-size: cover;
  Background-attachment: fixed;
}

.Title-group {
  padding: 20px 0 20px 0;
  overflow: hidden;
  text-align: center;

  h2 {
    margin: 0;
    font-size: 16px;
    line-height: 30px;
    height: 30px;
    padding: 5px 0;
    text-align: center;
    color: #ccc;

    span {
      display: inline-block;
      padding: 0;
    }

    .c-black {
      color: #888;
    }
  }

  p {
    line-height: 30px;
    font-size: 12px;
    color: #fff;
  }
  span{
    font-size: 12px;
  }
}

.service-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

}

.sitem {
  display: flex;
  flex-direction: column;
  width: 46%;
  background: #2ca9e1;
  margin-bottom: 10px;
  border-radius: 5px;
  color: white;
  align-items: center;
  padding-bottom: 20px;

  .big-icon {
    border-radius: 50%;
    display: block;
    text-align: center;
    width: 60px;
    height: 60px;
    margin-top: 20px;
    transition: opacity 0.2s linear;
    background: white;

    img {
      width: 60px;
      height: 60px;
    }
  }

  h4 {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;

    span:before {
      content: "\A";
      white-space: pre;
    }

    span {
      font-size: 12px;
    }
  }

  p {
    font-size: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    span {
      display: flex;
      line-height: 20px;
      text-align: center;
    }
  }
}

.service-item-abt {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 15px;

  h4 {
    align-self: center;
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  img {
    margin: 10px 0px;
  }
}

.contact-btn1 {
  height: 30px;
  line-height: 30px;
  display: inline-block;
  padding: 0 20px;
  font-size: 12px;
  color: #fff !important;
  text-decoration: none;
  background: #0A71FF;
  border-radius: 40px;
  margin-top: 15px;
}

.contact-btn1:active {
  background-color: rgba(221, 66, 80, 1);
  box-shadow: 0 0 0 4px rgba(221, 66, 80, 0.3)
}

.fright {
  float: none;
}

.tr {
  text-align: left;
}

.bg-red {
  background: #0A71FF;
}

.title-sub {
  color: white !important;
}

.title-sub.c-black {
  color: #333 !important;
}

.process-map {
  min-height: 150px;
  background-image: url("../../assets/images/process_map.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0px 20px;
}

.tip-txt {
  text-align: center;
  padding: 40px 20px;
  font-size: 16px;
  line-height: 30px;
  color: #666;
  background-image: url("../../assets/images/dot_tip.png");
  background-repeat: no-repeat;
  background-position: center top;
}

.see-btn-center {
  padding: 60px 0;
  text-align: center;
}

.title-sub-t {
  color: white;
  font-size: 12px;
  opacity: 0.8;
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .service-warp {
      border-top: 0px solid #ddd;
    }

    .fright {
      float: right !important;
    }

    .tr {
      text-align: right !important;
    }

    .service-bg {
      background-image: url("../../assets/images/2.jpg");
      background-position: center center;
      background-size: cover;
      Background-attachment: fixed;
    }

    .sabt {
      min-height: 380px;
      border-bottom: 1px solid #ddd;
    }

    .sitembg1 {
      background-image: url("../../assets/images/sitem_bg1.png");
      background-position: right bottom;
      background-repeat: no-repeat;
    }

    .sitembg2 {
      background-image: url("../../assets/images/sitem_bg2.png");
      background-position: left center;
      background-repeat: no-repeat;
    }

    .sitembg3 {
      background-image: url("../../assets/images/sitem_bg3.png");
      background-position: right center;
      background-repeat: no-repeat;
    }

    .sitembg4 {
      background-image: url("../../assets/images/sitem_bg4.png");
      background-position: left center;
      background-repeat: no-repeat;
    }

    .w1000 {
      width: 1000px;
      margin: auto;
      overflow: hidden
    }

    .service-item-abt {
      width: 46%;

      img {
        display: none;
      }
    }

    .service-item-abt h4 {
      margin: 0;
      padding: 0;
      font-size: 40px;
      line-height: 50px;
      font-weight: 100;
    }

    .service-item-abt h5 {
      margin: 0 0 10px 0;
      padding: 0;
      font-size: 18px;
      line-height: 30px;
      font-weight: 100;
    }

    .service-item-abt p {
      weight: 420px;
      margin: 0 0 10px 0;
      font-size: 14px;
      line-height: 24px;
      color: #666;
    }

    .service-item-abt .see-btn {
      margin: 40px 0 0 0;
    }

    .service-item-abt .see-btn {
      margin: 40px 0 0 0;
    }

    .Title-group {
      padding: 0 0 40px 0;
      overflow: hidden;
      text-align: center;
    }

    .Title-group h2 {
      margin: 0;
      font-size: 26px;
      font-weight: 100;
      line-height: 40px;
      height: 40px;
      padding: 5px 0;
      text-align: center;
      color: #ccc;
    }

    .Title-group h2 span {
      display: inline-block;
      padding: 0;
    }

    .Title-group p {
      font-size: 16px;
      line-height: 30px;
      color: #888;
    }

    .Title-group .animate-btn {
      float: right;
    }

    .Title-group .animate-btn a {
      margin: 5px;
    }


    .Title-group h2.title-sub {
      font-size: 44px;
      color: #fff;
      height: 50px;
      font-weight: 100;
    }

    .Title-group h2.c-black {
      color: #121212 !important;
    }

    .Title-group span {
      display: block;
      font-size: 22px;
      opacity: 0.87 !important;
      color: #121212 !important;
      font-weight: 100;

      &.title-sub-t {
        color: white !important;
        opacity: 0.8 !important;
      }
    }

    .Title-group p.sub-tit {
      font-size: 22px;
      color: #fff;
    }

    .see-btn-center {
      padding: 60px 0;
      text-align: center;
    }

    .see-btn-center .contact-btn1 {

    }

    .see-btn-center2 {
      padding: 20px 0;
      text-align: center;
    }

    .see-btn-center2 .contact-btn1 {
      padding: 5px 60px;
      font-size: 26px;
    }

    .tip-txt {
      text-align: center;
      padding: 40px 20px;
      font-size: 18px;
      line-height: 30px;
      color: #666;
      background-image: url("../../assets/images/dot_tip.png");
      background-repeat: no-repeat;
      background-position: center top;
    }

    .bg-red {
      background: #0A71FF;
    }

    .service-item {
      overflow: hidden;
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
    }


    .sitem {
      display: block;
      float: left;
      width: 21%;
      margin: 2%;
      background: #2ca9e1;
      -moz-transition: all 300ms;
      -o-transition: all 300ms;
      -webkit-transition: all 300ms;
      transition: all 300ms;
      padding-bottom: 0px !important;
    }

    .sitem h4 {
      margin: 20px auto;
      font-size: 30px !important;
      font-weight: 100 !important;
      color: #fff;
      text-align: center;
    }

    .sitem h4 span {
      display: block;
      font-size: 16px !important;
    }

    .sitem h4 em {
      display: block;
      margin: 0 auto;
      height: 100px;
      white-space: nowrap;
    }

    .sitem p {
      padding: 5px 20px 10px;
      text-align: center;
      font-size: 16px !important;
      line-height: 30px !important;
      color: #fff;
      height: 140px;
    }

    .sitem p span {
      display: inline-block;
      margin: 0 10px;
      line-height: 30px !important;
    }

    .contact-btn1 {
      box-shadow: 0 0 0 1px rgba(221, 66, 80, 0);
      -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    }

    .contact-btn1, .contact-btn1:hover {
      height: 40px;
      line-height: 40px;
      display: inline-block;
      padding: 0 40px;
      font-size: 20px;
      color: #fff;
      text-decoration: none;
      background: #0A71FF;
      background-color: #0A71FF;
      border-radius: 40px;
    }

    .contact-btn1:hover {
      background-color: rgba(221, 66, 80, 1);
      box-shadow: 0 0 0 4px rgba(221, 66, 80, 0.3)
    }

    .process-map {
      padding: 60px 0;
    }

    .process-map {
      min-height: 250px;
      background-image: url("../../assets/images/process_map.png");
      background-position: center;
      background-repeat: no-repeat;
    }

    /*图片旋转*/
    .bg {
      background: #FFF;
      margin: 0 auto;
    }

    .bg:hover {
      background: #2FD0B5;
      transition: background-color 0.3s linear 0s;
    }

    .big-icon, .big-icon:hover {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      display: block;
      text-align: center;
      width: 96px!important;
      height: 96px!important;
      margin-top: 20px;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }

    .sitem a .big-icon i {
      -webkit-transition: -webkit-transform .3s ease-out;
      -moz-transition: -moz-transform .3s ease-out;
      -o-transition: -o-transform .3s ease-out;
      transition: transform .3s ease-out;
    }

    .sitem a:hover .big-icon i {
      -webkit-transform: scale(1) rotate(75deg);
      -moz-transform: scale(1) rotate(75deg);
      -o-transform: scale(1) rotate(75deg);
      -ms-transform: scale(1) rotate(75deg);
      transform: scale(1) rotate(75deg);
      opacity: 0.7;
    }

    .sitem a .big-icon h1 {
      margin-top: 10px;
      color: #c9c9c9;
      font-size: 22px;
    }

    .sitem a:hover .big-icon h1 {
      color: #3999d0;
    }
    .sitem .big-icon img{
      width: 96px !important;
      height: 96px !important;
    }
  }
}
</style>
