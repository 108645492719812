const API_BASE_URL = process.env.VUE_APP_API_BASE_URL || 'https://nodeapi.fxcxy.com';

export default {
 
  caseRecommendList: `${API_BASE_URL}/api/case/recommendList`, //案例推荐列表
  caseList: `${API_BASE_URL}/api/case/caseList`, //案例列表
  caseDetails: `${API_BASE_URL}/api/case/caseDetails`, //案例列表
  casePrevAndNext: `${API_BASE_URL}/api/case/casePrevAndNext`, //案例的上一个，下一个id
  caseRelated: `${API_BASE_URL}/api/case/caseRelated`, //底部案例
  newsList: `${API_BASE_URL}/api/news/list`, //新闻列表
  advertiseList: `${API_BASE_URL}/api/adMgr/bannerList`, //广告列表
  getHomeNews: `${API_BASE_URL}/api/news/getNews`, //首页新闻
  getRecommendNews: `${API_BASE_URL}/api/news/getRecommendNews`, //推荐新闻
  getNextNews: `${API_BASE_URL}/api/news/getNextNews`, //下一篇新闻
  newsDetails: `${API_BASE_URL}/api/news/getNewsDetails`, //新闻详情
  caseTypeList: `${API_BASE_URL}/api/case/type/caseTypeList`, //案例类型
  newsTypeList: `${API_BASE_URL}/api/news/type/newsTypeList`, //新闻类型
  
}
